/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { useStateContext } from "../contexts/ContextProvider";

import ReactPaginate from "react-paginate";

import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";

import NotFoundImg from "../assets/No data-pana.png";
import { TiInfoLarge } from "react-icons/ti";
import moment from "moment";

import Datepicker from "react-tailwindcss-datepicker";

const BASE_URL = window.config.API_URL;

const LogsList = () => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const { state: ContextState, getApiLogs } = useContext(ApisContext);
  const { logs, getLogsPending, allLogs } = ContextState;
  const { state: AuthContextState } = useContext(AuthContext);
  const { user } = AuthContextState;
  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageNo = parseInt(searchParams.get("page")) || 1;
  const status_code = parseInt(searchParams.get("statusCode"));
  const start_date = searchParams.get("startDate");
  const end_date = searchParams.get("endDate");
  const url = searchParams.get("endPointName");
  const method = searchParams.get("method");
  const search = searchParams.get("search");
  const ip_address = searchParams.get("ip_address");
  const user_id = searchParams.get("user_id");
  const [value, setValue] = useState({
    startDate: searchParams.get("startDate") || null,
    endDate: searchParams.get("endDate") || null,
  });

  const handleValueChange = (newValue) => {
    console.log("new date", newValue);
    const newParams = { ...Object.fromEntries(searchParams.entries()) };
    if (newValue?.startDate) {
      newParams.startDate = newValue?.startDate;
    } else {
      delete newParams.startDate;
    }
    if (newValue?.endDate) {
      newParams.endDate = newValue?.endDate;
    } else {
      delete newParams.endDate;
    }
    setSearchParams(newParams);
    setValue(newValue);
  };

  const handleMethodChange = (e) => {
    const method = e.target.value;
    const newParams = { ...Object.fromEntries(searchParams.entries()) };
    if (method || method != "") newParams.method = method;
    else delete newParams.method;
    newParams.page = 1; // Reset to page 1 on search
    setSearchParams(newParams);
  };

  const handleEndPointNameChange = (e) => {
    const endPointName = e.target.value;
    const newParams = { ...Object.fromEntries(searchParams.entries()) };
    if (endPointName) newParams.endPointName = endPointName;
    else delete newParams.endPointName;
    newParams.page = 1; // Reset to page 1 on search
    setSearchParams(newParams);
  };
  const handleSearchChange = (e) => {
    const search = e.target.value;
    const newParams = { ...Object.fromEntries(searchParams.entries()) };
    if (search) newParams.search = search;
    else delete newParams.search;
    newParams.page = 1; // Reset to page 1 on search
    setSearchParams(newParams);
  };
  const handleUserIdChange = (e) => {
    const user_id = e.target.value;
    const newParams = { ...Object.fromEntries(searchParams.entries()) };
    if (user_id) newParams.user_id = user_id;
    else delete newParams.user_id;
    newParams.page = 1; // Reset to page 1 on search
    setSearchParams(newParams);
  };

  const handleStatusCodeChange = (e) => {
    const statusCode = e.target.value;
    const newParams = { ...Object.fromEntries(searchParams.entries()) };
    if (statusCode) newParams.statusCode = statusCode;
    else delete newParams.statusCode;
    newParams.page = 1; // Reset to page 1 on search
    setSearchParams(newParams);
  };

  useEffect(() => {
    // Update page count when allLogs changes
    if (allLogs) {
      const total = allLogs?.total;
      const limit = allLogs?.per_page;
      setpageCount(Math.ceil(total / limit));
      setCurrentPage(parseInt(allLogs?.current_page) + 1);
    }
  }, [allLogs]);

  const handlePageClick = (data) => {
    // if (data.selected >= 0) {
    //   const page = data.selected + 1;
    //   setCurrentPage(page);

    //   if (searchQuery) {
    //     // Fetch logs when clicking on pagination with search
    //     getApiLogs(user, page, searchQuery);
    //   } else {
    //     // Fetch logs when clicking on pagination without search
    //     getApiLogs(user, page);
    //   }
    // }
    const page = data.selected + 1;
    const newParams = { ...Object.fromEntries(searchParams.entries()) };
    newParams.page = page;
    setSearchParams(newParams);
  };

  useEffect(() => {
    let endPoint = "logs?";

    const params = [];
    if (pageNo) params.push(`page=${pageNo}`); // Ensure page number is included
    if (status_code) params.push(`status_code=${status_code}`);
    if (start_date) params.push(`start_date=${start_date}`);
    if (end_date) params.push(`end_date=${end_date}`);
    if (user_id) params.push(`user_id=${user_id}`);
    if (url) params.push(`url=${url}`);
    if (method) params.push(`method=${method}`);
    if (ip_address) params.push(`ip_address=${ip_address}`);
    if (search) params.push(`search=${search}`);

    // Join all available parameters with '&' and append to base URL
    endPoint += params.join("&");

    getApiLogs(user, endPoint);
  }, [
    user,
    pageNo,
    status_code,
    start_date,
    end_date,
    user_id,
    method,
    search,
    url,
    ip_address,
  ]);

  return (
    <div className="px-5  sm:px-10 sm:mt-8 mt-24">
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-3">
        Api Logs
      </div>
      {/* <div className="bg-white dark:bg-gray-600 dark:border-gray-800 rounded-lg my-4 p-4 border flex flex-col sm:flex-row justify-between items-center gap-2">
        <div className="flex items-center gap-4 w-full sm:w-fit">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              autoComplete="off"
              type="text"
              id="simple-search"
              onChange={(e) => setSearchQuery(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder="Search"
            />
          </div>
        </div>
      </div> */}
      <div className="bg-white dark:bg-gray-600 dark:border-gray-800 rounded-lg my-4 p-3 px-5 border ">
        <div className="flex flex-wrap md:flex-nowrap justify-between items-center gap-2">
          <div className="w-full">
            <label className="text-md font-medium text-gray-900 dark:text-white mb-3">
              Select Start To End Dates
            </label>
            <Datepicker
              value={value}
              popoverDirection="down"
              onChange={handleValueChange}
              inputClassName="bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            />
          </div>
          <div className="w-full">
            <label className="text-md font-medium text-gray-900 dark:text-white mb-3">
              Status Code
            </label>
            <input
              autoComplete="off"
              type="number"
              id="statusCode"
              value={searchParams.get("statusCode")}
              onChange={(e) => handleStatusCodeChange(e)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder="Enter Status Code"
            />
          </div>
        </div>
        <div className="flex flex-wrap md:flex-nowrap justify-between items-center gap-2 mt-3">
          <div className="w-full">
            <label className="text-md font-medium text-gray-900 dark:text-white mb-3">
              Select Api Method
            </label>
            <select
              id="method"
              onChange={(e) => {
                handleMethodChange(e);
              }}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option defaultValue value={""}>
                Select Method
              </option>
              <option
                selected={searchParams.get("method") == "POST"}
                value={"POST"}
              >
                Post
              </option>
              <option
                selected={searchParams.get("method") == "GET"}
                value={"GET"}
              >
                Get
              </option>
              <option
                selected={searchParams.get("method") == "PUT"}
                value={"PUT"}
              >
                Put
              </option>
              <option
                selected={searchParams.get("method") == "DELETE"}
                value={"DELETE"}
              >
                Delete
              </option>
            </select>
          </div>
          <div className="w-full">
            <label className="text-md font-medium text-gray-900 dark:text-white mb-3">
              End point name
            </label>
            <input
              autoComplete="off"
              type="text"
              id="endPointName"
              value={searchParams.get("endPointName")}
              onChange={(e) => handleEndPointNameChange(e)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder="End point name"
            />
          </div>
        </div>
        <div className="flex flex-wrap md:flex-nowrap justify-between items-center gap-2 mt-3">
          <div className="w-full">
            <label className="text-md font-medium text-gray-900 dark:text-white mb-3">
              Search
            </label>
            <input
              autoComplete="off"
              type="text"
              id="search"
              value={searchParams.get("search")}
              onChange={(e) => handleSearchChange(e)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder="Search"
            />
          </div>
          <div className="w-full">
            <label className="text-md font-medium text-gray-900 dark:text-white mb-3">
              User Id
            </label>
            <input
              autoComplete="off"
              type="text"
              id="user_id"
              value={searchParams.get("user_id")}
              onChange={(e) => handleUserIdChange(e)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder="User Id"
            />
          </div>
        </div>
      </div>
      <div className="align-middle inline-block w-full shadow-lg overflow-x-auto rounded-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-sm text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="ps-6 px-3 py-3">
                Status
              </th>
              <th scope="col" className="ps-6 px-3 py-3 whitespace-nowrap">
                Time
              </th>
              <th scope="col" className="px-3 py-3">
                Method
              </th>

              <th scope="col" className="px-3 py-3">
                Url
              </th>
              <th scope="col" className="px-3 py-3 pe-6">
                VIEW
              </th>
            </tr>
          </thead>
          <tbody>
            {getLogsPending && (
              <>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={11}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
              </>
            )}
            {!getLogsPending &&
              logs &&
              logs?.map((log, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="ps-6 px-3 py-2 font-medium whitespace-nowrap dark:text-white flex justify-center">
                      <p
                        className={`${
                          log?.status_code === "200"
                            ? "text-green-700"
                            : "text-red-500"
                        }`}
                      >
                        {log?.status_code}
                      </p>
                    </td>
                    <th
                      scope="row"
                      className="ps-6 px-3 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {moment(log?.request_time).format("MMM-DD-YYYY HH:mm:ss")}
                    </th>
                    <td className="px-3 py-4 text-center">{log?.method}</td>
                    <td className="px-3 py-4">{log?.url}</td>

                    <td className="px-3 py-4 text-xl pe-6 flex justify-center">
                      <Link to={`/apilogs/viewLog/${log?.id}`}>
                        <TiInfoLarge />
                      </Link>
                    </td>
                  </tr>
                );
              })}

            {!getLogsPending && !logs && (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td colSpan={11} className="p-4 py-2 ">
                  <div className="flex justify-center h-[calc(100vh_-_250px)] items-left md:items-center flex-col md:flex-row">
                    <img className="max-h-56" src={NotFoundImg} alt="" />
                  </div>
                </td>
              </tr>
            )}
            {(allLogs !== null || logs?.length) && (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td colSpan={11} className="p-4 py-2 ">
                  <div className="flex justify-between items-left md:items-center flex-col md:flex-row">
                    <div className="whitespace-nowrap">
                      <span className="text-sm text-gray-700 dark:text-gray-400">
                        Showing{" "}
                        <span className="font-semibold text-gray-900 dark:text-white">
                          {allLogs?.from}
                        </span>{" "}
                        to{" "}
                        <span className="font-semibold text-gray-900 dark:text-white">
                          {allLogs?.to}
                        </span>{" "}
                        of{" "}
                        <span className="font-semibold text-gray-900 dark:text-white">
                          {allLogs?.total}
                        </span>{" "}
                        Entries
                      </span>
                    </div>

                    <ReactPaginate
                      previousLabel={<GrFormPrevious />}
                      nextLabel={<GrFormNext />}
                      breakLabel={"..."}
                      forcePage={currentPage - 2}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={" items-center inline-flex space-x-2"}
                      pageClassName={
                        "page-link cursor-pointer font-semibold rounded-full h-10 w-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                      }
                      pageLinkClassName="h-full w-full rounded-full flex justify-center items-center text-center"
                      previousLinkClassName={
                        "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                      }
                      disabledLinkClassName={"text-gray-400 opacity-50"}
                      nextLinkClassName={
                        "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                      }
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"p-0 pt-0 pr-0 pb-0 pl-0"}
                      activeLinkClassName={
                        "active font-semibold rounded-full h-full w-full leading-tight text-gray-50 bg-gray-800 border border-gray-300 hover:bg-gray-700 hover:text-gray-100 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-300 dark:hover:text-black flex justify-center items-center text-center"
                      }
                    />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LogsList;
