/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { MdOutlineDelete, MdAdd, MdDeleteOutline } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import AddProduct from "./AddProduct";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { useStateContext } from "../contexts/ContextProvider";

import ReactPaginate from "react-paginate";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";

import NotFoundImg from "../assets/No data-pana.png";

const Product = () => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  // const [searchQuery, setSearchQuery] = useState("");
  // const [category, setCategory] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get("query") || "";
  const pageNo = parseInt(searchParams.get("page")) || 1;
  const category = parseInt(searchParams.get("category")) || 0;
  const {
    state: ContextState,
    deleteProducts,
    getCategories,
    fillterProducts,
  } = useContext(ApisContext);
  const { products, getProductsPending, allProducts, categories } =
    ContextState;
  const { state: AuthContextState } = useContext(AuthContext);
  const { user } = AuthContextState;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(pageNo ? pageNo : 0);
  const [visible, setVisible] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);

  useEffect(() => {
    getCategories(user);
  }, []);

  useEffect(() => {
    const formData = new FormData();
    formData.append("is_old", 0);
    if (searchQuery) {
      formData.append("search", searchQuery);
    }
    if (category) {
      formData.append("category_id", category);
    }
    fillterProducts(formData, user, pageNo);
  }, [pageNo, searchQuery, user, searchParams]);

  useEffect(() => {
    if (allProducts) {
      const total = allProducts?.total;
      const limit = allProducts?.per_page;
      const newPageCount = Math.ceil(total / limit);
      setPageCount(Math.ceil(total / limit));
    }
  }, [allProducts, products]);

  
  const handlePageClick = (data) => {
    const page = data.selected + 1;
    const newParams = { ...Object.fromEntries(searchParams.entries()) };
    newParams.page = page;
    setSearchParams(newParams);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    const newParams = { ...Object.fromEntries(searchParams.entries()) };
    if (query) newParams.query = query;
    else delete newParams.query;
    newParams.page = 1; // Reset to page 1 on search
    setSearchParams(newParams);
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    const newParams = { ...Object.fromEntries(searchParams.entries()) };
    if (category || category != '0') newParams.category = category;
    else delete newParams.category;
    newParams.page = 1; // Reset to page 1 on search
    setSearchParams(newParams);
  };

  const handelDeleteProduct = (productId) => {
    setDeleteProductId(productId);
    setVisible(true);
  };
  const accept = () => {
    deleteProducts(deleteProductId, user);
  };
  const reject = () => {
    setDeleteProductId(null);
  };

  return (
    <div className="px-5  sm:px-10 sm:mt-8 mt-24">
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to proceed?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-3">
        New Books
      </div>
      <div className="bg-white dark:bg-gray-600 dark:border-gray-800 rounded-lg my-4 p-4 border flex flex-col sm:flex-row justify-between items-center gap-2">
        <div className="flex items-center gap-4 w-full sm:w-fit">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              autoComplete="off"
              type="text"
              id="simple-search"
              onChange={(e) => handleSearchChange(e)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder="Search"
            />
          </div>
        </div>
        <div className="flex flex-wrap lg:flex-nowrap items-center gap-3 w-full sm:w-fit">
          <Link className="w-full" to={`/newbooks/addBook/new`}>
            <button
              type="button"
              style={{
                background: currentColor,
              }}
              className="w-full justify-center sm:w-fit focus:outline-none text-white font-medium rounded-lg text-base px-3 py-2.5 hover:opacity-80 flex items-center gap-1"
            >
              <div className="text-xl">
                <MdAdd />
              </div>
              Add Book
            </button>
          </Link>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-600 dark:border-gray-800 rounded-lg my-4 p-5 border  flex flex-wrap md:flex-nowrap justify-between items-center gap-2">
        <select
          id="category"
          onChange={(e) => {
            handleCategoryChange(e);
          }}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option defaultValue value={0}>
            All Categories
          </option>
          {categories?.map((item, index) => (
            <option selected={category == item?.id} key={index} value={item?.id}>
              {item?.category_name}
            </option>
          ))}
        </select>
      </div>
      <div className="align-middle inline-block w-full shadow-lg overflow-x-auto rounded-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-sm text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="ps-6 px-3 py-3">
                Product Image
              </th>
              <th scope="col" className="ps-6 px-3 py-3 whitespace-nowrap">
                Product name
              </th>
              <th scope="col" className="px-3 py-3">
                Category
              </th>

              <th scope="col" className="px-3 py-3">
                Price
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                SALE PRICE
              </th>
              <th scope="col" className="px-3 py-3">
                STOCK
              </th>
              <th scope="col" className="px-3 py-3">
                STATUS
              </th>
              <th scope="col" className="px-3 py-3">
                VIEW
              </th>

              <th scope="col" className="px-3 py-3 pe-5">
                ACTIONS
              </th>
            </tr>
          </thead>
          <tbody>
            {getProductsPending && (
              <>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={11}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full dark:bg-gray-700">
                        <svg
                          className="w-6 h-6 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                      </div>
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={11}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full dark:bg-gray-700">
                        <svg
                          className="w-6 h-6 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                      </div>
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={11}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full dark:bg-gray-700">
                        <svg
                          className="w-6 h-6 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                      </div>
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={11}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full dark:bg-gray-700">
                        <svg
                          className="w-6 h-6 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                      </div>
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={11}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full dark:bg-gray-700">
                        <svg
                          className="w-6 h-6 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                      </div>
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={11}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full dark:bg-gray-700">
                        <svg
                          className="w-6 h-6 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                      </div>
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
              </>
            )}
            {!getProductsPending &&
              products &&
              products?.map((product, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="ps-6 px-3 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white flex justify-center">
                      <div className="rounded-lg h-12 w-12 border flex justify-center items-center">
                        <img
                          src={product?.product_image?.[0]?.image}
                          className="rounded-lg h-12 w-12 border object-cover"
                          alt="..."
                        />
                      </div>
                    </td>
                    <th
                      scope="row"
                      className="ps-6 px-3 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      <div className="whitespace-normal">
                        {product?.title?.substr(0, 23)}
                        {product?.title?.length > 20 ? "..." : ""}
                      </div>
                    </th>
                    <td className="px-3 py-4">
                      {(
                        product?.categories?.map(
                          (cat, index) => cat.category_name
                        ) || []
                      )
                        .join(", ")
                        .substr(0, 25)}
                      {(
                        product?.categories?.map(
                          (cat, index) => cat.category_name
                        ) || []
                      ).join("").length > 25
                        ? "..."
                        : ""}
                    </td>
                    <td className="px-3 py-4 font-semibold text-center">
                      {product?.price}
                    </td>
                    <td className="px-3 py-4 font-semibold text-center">
                      {Math.round(
                        product?.price -
                          (product?.price * product?.discount_percent) / 100
                      )}
                    </td>
                    <td className="px-3 py-4">{product?.stock}</td>
                    <td className="px-3 py-4">
                      {product?.stock > 0 ? (
                        <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                          Selling
                        </span>
                      ) : (
                        <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                          SoldOut
                        </span>
                      )}
                    </td>
                    <td className="px-3 py-4 text-xl text-wk-align">
                      <Link to={`/books/viewBook/${product?.id}`}>
                        <BsFillEyeFill />
                      </Link>
                    </td>
                    <td className="px-3 py-4">
                      <div className="text-2xl flex items-center gap-2">
                        <Link
                          to={`/books/editBook/${product?.id}`}
                          className="font-medium text-green-600 dark:text-blue-500 hover:underline"
                        >
                          <HiOutlinePencilAlt />
                        </Link>
                        <button
                          onClick={() => handelDeleteProduct(product?.id)}
                          className="font-medium text-red-600 dark:text-red-500 hover:underline"
                        >
                          <MdOutlineDelete />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}

            {!getProductsPending && !products && (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td colSpan={11} className="p-4 py-2 ">
                  <div className="flex justify-center h-[calc(100vh_-_250px)] items-left md:items-center flex-col md:flex-row">
                    <img className="max-h-56" src={NotFoundImg} alt="" />
                  </div>
                </td>
              </tr>
            )}
            {(allProducts !== null || products !== null) &&
              (!searchQuery || category == 0) && (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td colSpan={11} className="p-4 py-2 ">
                    <div className="flex justify-between items-left md:items-center flex-col md:flex-row">
                      <div className="whitespace-nowrap">
                        <span className="text-sm text-gray-700 dark:text-gray-400">
                          Showing{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {allProducts?.from}
                          </span>{" "}
                          to{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {allProducts?.to}
                          </span>{" "}
                          of{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {allProducts?.total}
                          </span>{" "}
                          Entries
                        </span>
                      </div>

                      <ReactPaginate
                        previousLabel={<GrFormPrevious />}
                        nextLabel={<GrFormNext />}
                        breakLabel={"..."}
                        initialPage={pageNo ? pageNo - 1 : currentPage}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={
                          " items-center inline-flex space-x-2"
                        }
                        pageClassName={
                          "page-link cursor-pointer font-semibold rounded-full h-10 w-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        pageLinkClassName="h-full w-full rounded-full flex justify-center items-center text-center"
                        previousLinkClassName={
                          "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        disabledLinkClassName={"text-gray-400 opacity-50"}
                        nextLinkClassName={
                          "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"p-0 pt-0 pr-0 pb-0 pl-0"}
                        activeLinkClassName={
                          "active font-semibold rounded-full h-full w-full leading-tight text-gray-50 bg-gray-800 border border-gray-300 hover:bg-gray-700 hover:text-gray-100 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-300 dark:hover:text-black flex justify-center items-center text-center"
                        }
                      />
                    </div>
                  </td>
                </tr>
              )}
            {(allProducts === null || products === null) &&
              (searchQuery || category != 0) && (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td colSpan={11} className="p-4 py-2 ">
                    <div className="flex justify-between items-left md:items-center flex-col md:flex-row">
                      <div className="whitespace-nowrap">
                        <span className="text-sm text-gray-700 dark:text-gray-400">
                          Showing{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {products?.length || 0}
                          </span>{" "}
                          Entries
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Product;
